<template>
    <div class="row" style="padding: 10px; background-color: blue">
        <div>
            <table class="table table-sm" style="font-size: 55%; color: white; width: 100%">
                <thead class="thead2">
                    <tr>

                    </tr>
                </thead>
                <tbody  v-for="service in Services" :key="service.key" class="borderBottom">
                    <tr :class="new Date(service.date).toLocaleString([], {weekday: 'long'})">
                        <td class="td2" rowspan=3 colspan=1>
                            <b>{{ new Date(service.date).toLocaleString([], {weekday: 'short'}) }} <br>
                        {{ new Date(service.date).toLocaleString([], {year: 'numeric', month: 'short', day: 'numeric'}) }}<br>
                        {{ new Date(service.date).toLocaleString([], {hour: '2-digit', minute: '2-digit'}) }}</b>
                        <h5 style="text-align: right; margin-right: 5px; margin-bottom: 0px"><b>{{ service.bl }}</b></h5></td>
                    </tr>
                    <tr class="borderSmall">
                        <td v-if="service.covid == true" class="covid td2" style="font-size: 150%"><b>{{ service.name }}</b></td>
                        <td class="td2" v-if="service.covid != true" style="font-size: 150%"><b>{{ service.name }}</b></td>
                        <td class="td2"><span v-if="service.visitationstart != null && service.visitationstart != ''"><b>Visitation Starts: </b>{{new Date(service.visitationstart).toLocaleString([], {weekday: 'long'})}} {{new Date(service.visitationstart).toLocaleString([], {year: 'numeric', month: 'short', day: 'numeric'})}} at {{new Date(service.visitationstart).toLocaleString([], {hour: '2-digit', minute: '2-digit'})}}<br><b>Ends: </b>{{new Date("February 04, 2011 "+service.visitationend).toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})}}</span></td>
                    </tr>
                    <tr class="borderBottom">
                        <td class="td2"><span style="white-space: nowrap">{{ service.serviceLoc }}</span><br><span style="white-space: nowrap">{{ service.cem }} <svg v-if="service.contacted == true" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/></svg></span></td>
                        <td v-if="currentTenant != waldropfh" class="td2"><span v-if="service.director != null && service.director != ''"><b>Director: </b></span>{{service.director}}</td>
                        <td v-if="currentTenant == waldropfh" class="td2" colspan=1 v-html="service.pers"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { db } from '../firebaseDb';
    export default {
        data() {
            return {
                Services: [],
                timer: '',
                currentTenant: "",
                weekday: '',
            }
        },
        
        created() {
            this.currentTenant = this.$tenant;
            this.updateservices();
        },
        mounted: function () {
        window.setInterval(() => {
            this.autorefresh()
        }, 7200000)
        },
        methods: {
            updateservices() {
                db.collection('services').orderBy('date').onSnapshot((snapshotChange) => {
                this.Services = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.currentTenant) {
                    if(new Date(doc.data().date).getTime() >= new Date(new Date().setHours(0, 0, 0, 0)).getTime() && doc.data().pending !== true && doc.data().deleted !== true  && doc.data().archivedtime == null) {
                    this.Services.push({
                        key: doc.id,
                        name: doc.data().name,
                        notes: doc.data().notes,
                        date: doc.data().date,
                        serviceLoc: doc.data().serviceLoc,
                        cem: doc.data().cem,
                        veh: this.vehFormat(doc.data().veh, doc.data().limocount),
                        pers: this.persFormat(doc.data().pers, doc.data().dir),
                        bl: doc.data().bl,
                        sr: this.viewservicerequirements(doc.data().srs),
                        music: doc.data().music,
                        casket: doc.data().casket,
                        cemnotes: doc.data().cemnotes,
                        cremation: doc.data().cremation,
                        covid: doc.data().covid,
                        director: doc.data().dir,
                        contacted: doc.data().cemContacted,
                        visitationstart: doc.data().visitationstart,
                        visitationend: doc.data().visitationend,
                    })
                    }
                    }
                });
            })
            },
            
            autorefresh() {
                this.$router.go();
            },
            vehFormat(vehs, lcount) {
                if(vehs != "None") {
                   let reqs = vehs.split(', ')
                   let formatted = ""
                   formatted += "<b>Vehicles: </b> <br>"
                   if(vehs.includes('Limo')) {
                   formatted += "Limo("+lcount +")<br>"
                   }
                   for(let i = 0; i < reqs.length; i++) {
                       if(reqs[i].trim() != 'Limo') {
                           formatted += reqs[i]
                           formatted += "<br> "
                       }
                       
                   }
                   return formatted
                }
                else {
                    return "<b>Vehicles: </b> <br> None"
                }
            },
            persFormat(people, director) {
                if(people != "" && people != null) {
                   let reqs = people
                   console.log("PERSSSSSSSS: ",reqs)
                   let formatted = ""
                   formatted += "<b>Personnel</b> <br>"
                   if(director != null && director != "") {
                   formatted += director+" (D), "
                   }
                   for(let i = 0; i < reqs.length; i++) {
                       if(reqs[i].name != director) {
                           formatted += reqs[i].name
                           if(i+1 < reqs.length) {
                            formatted += ", "
                           }
                       }
                   }
                   return formatted
                }
                else {
                    return "<b>Personnel</b> <br> None"
                }
            },
            viewservicerequirements(srs) {
               console.log("AAAAAAAAAAAA", srs) 
               let reqs = srs
               let formatted = ""
               formatted += "<b>Service Requirements: </b>"
               for(let i = 0; i < reqs.length; i++) {
                   if(reqs[i].done) {
                       formatted += '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="black" class="bi bi-check" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/></svg>'
                   }
                   formatted += reqs[i].name
                   if(i+1 < reqs.length) {
                            formatted += ", "
                           }
               }
               return formatted
            },
            deleteService(id){
              if (window.confirm("Do you really want to delete?")) {
                db.collection("services").doc(id).delete().then(() => {
                    console.log("Service deleted!");
                })
                .catch((error) => {
                    console.error(error);
                })
              }
            }
        }
    }
</script>

<style>
    table{
    /*table-layout: fixed;*/
    }
    .Monday {
        background-color: red;
    }
    .Tuesday {
        background-color: orange;
    }
    .Wednesday {
        background-color: gold;
    }
    .Thursday {
        background-color: green;
    }
    .Friday {
        background-color: darkblue;
    }
    .Saturday {
        background-color: indigo;
    }
    .Sunday {
        background-color: deeppink;
    }
    .td2{
    word-wrap:break-word;
    text-shadow: 0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000;
    }
    .thead2{
        font-size: 150%;
    color: aqua;
    word-wrap:break-word;
    text-shadow: 0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000;
    }
    .no-border td {
        border: 0px;
    }
    .borderBottom td {
        border-bottom: 4px solid white;
    }
    .borderSmall td {
        border-bottom: 1px solid gray;
    }
    .borderTop {
        border-top: 3px solid white;
    }
    .covid {
        color: red;
    }

</style>