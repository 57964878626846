import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import('../components/Welcome')
  },
  {
    path: '/payment/:id',
    name: 'payment',
    component: () => import('../components/Payment')
  },
  {
    path: '/help/',
    name: 'help',
    component: () => import('../components/Help')
  },
  {
    path: '/add',
    name: 'add',
    component: () => import('../components/ServiceCreate'),
        meta:{
          doc: "adding-a-service"
    }

  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../components/Profile')
  },
  {
    path: '/signup/:id',
    name: 'signup',
    component: () => import('../components/SignUp')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../components/Admin'),
    meta:{
          doc: "adding-new-accounts"
    }
  },
  {
    path: '/adminadd',
    name: 'adminadd',
    component: () => import('../components/AdminAdd'),
        meta:{
          doc: "adding-new-accounts"
    }

  },
   {
    path: '/superadminadd',
    name: 'superadminadd',
    component: () => import('../components/SuperAdminAdd'),
    meta:{
          doc: "adding-new-accounts"
    }

  },
  {
    path: '/containerlist',
    name: 'containerlist',
    component: () => import('../components/ContainerList'),
    meta:{
          doc: "using-settings/containers"
    }
  },
  {
    path: '/containeradd',
    name: 'containeradd',
    component: () => import('../components/ContainerAdd'),
    meta:{
          doc: "using-settings/containers"
    }
  },
  {
    path: '/containeredit',
    name: 'containeredit',
    component: () => import('../components/ContainerEdit'),
    meta:{
          doc: "using-settings/containers"
    }
  },
  {
    path: '/',
    name: 'list',
    component: () => import('../components/MobileDisplay'),
    meta:{
          doc: "current-archived-and-pending-services"
    }
  },
  {
    path: '/archive',
    name: 'archive',
    component: () => import('../components/Archive'),
    meta:{
          doc: "current-archived-and-pending-services"
    }
  },
  {
    path: '/pending',
    name: 'pending',
    component: () => import('../components/Pending'),
    meta:{
          doc: "current-archived-and-pending-services"
    }
  },
  {
    path: '/deleted',
    name: 'deleted',
    component: () => import('../components/RecentlyDeleted'),
    meta:{
          doc: "using-settings/recently-deleted"
    }
  },
  {
    path: '/tv',
    name: 'tv',
    component: () => import('../components/TVDisplay'),
    meta:{
          doc: "tv-view"
    }
  },
  {
    path: '/condensed',
    name: 'condensed',
    component: () => import('../components/Condensed'),
    meta:{
          doc: "condensed-view"
    }
  },
   {
    path: '/cfl',
    name: 'cfl',
    component: () => import('../components/CFList')
  },
  {
    path: '/cfadd',
    name: 'CFAdd',
    component: () => import('../components/CFAdd')
  },
  {
    path: '/cfedit',
    name: 'CFEdit',
    component: () => import('../components/CFEdit')
  },
  {
    path: '/sl',
    name: 'serviceLoc',
    component: () => import('../components/ServiceLocList'),
    meta:{
          doc: "using-settings/service-locations-and-cemeteries"
    }
  },
  {
    path: '/slc',
    name: 'serviceLocCreate',
    component: () => import('../components/ServiceLocationCreate'),
    meta:{
          doc: "using-settings/service-locations-and-cemeteries"
    }
  },
  {
    path: '/sle',
    name: 'serviceLocEdit',
    component: () => import('../components/ServiceLocEdit'),
    meta:{
          doc: "using-settings/service-locations-and-cemeteries"
    }
  },
  {
    path: '/cl',
    name: 'cem',
    component: () => import('../components/CemList'),
    meta:{
          doc: "using-settings/service-locations-and-cemeteries"
    }
  },
  {
    path: '/cemCreate',
    name: 'cemCreate',
    component: () => import('../components/CemCreate'),
    meta:{
          doc: "using-settings/service-locations-and-cemeteries"
    }
  },
  {
    path: '/cle',
    name: 'cemEdit',
    component: () => import('../components/CemEdit'),
    meta:{
          doc: "using-settings/service-locations-and-cemeteries"
    }
  },
  {
    path: '/casketlist',
    name: 'casketList',
    component: () => import('../components/CasketList'),
    meta:{
          doc: "using-settings/casket-inventory"
    }
  },
  {
    path: '/casketadd',
    name: 'casketAdd',
    component: () => import('../components/CasketAdd'),
    meta:{
          doc: "using-settings/casket-inventory/adding-editing-and-removing-caskets-from-inventory"
    }
  },
  {
    path: '/casketedit',
    name: 'casketEdit',
    component: () => import('../components/CasketEdit'),
    meta:{
          doc: "using-settings/casket-inventory/adding-editing-and-removing-caskets-from-inventory"
    }
  },
  {
    path: '/casketreconcile',
    name: 'casketreconcile',
    component: () => import('../components/CasketReconcile'),
    meta:{
          doc: "using-settings/casket-inventory/inventory-reconciliation"
    }
  },
  {
    path: '/colorlist',
    name: 'colorList',
    component: () => import('../components/ColorList'),
    meta:{
          doc: "using-settings/casket-inventory/casket-colors"
    }
  },
  {
    path: '/coloradd',
    name: 'colorAdd',
    component: () => import('../components/ColorAdd'),
    meta:{
          doc: "using-settings/casket-inventory/casket-colors"
    }
  },
  {
    path: '/coloredit',
    name: 'colorEdit',
    component: () => import('../components/ColorEdit'),
    meta:{
          doc: "using-settings/casket-inventory/casket-colors"
    }
  },
  {
    path: '/vl',
    name: 'veh',
    component: () => import('../components/VehList'),
    meta:{
          doc: "using-settings/vehicles"
    }
  },
  {
    path: '/vlc',
    name: 'vehAdd',
    component: () => import('../components/VehAdd'),
    meta:{
          doc: "using-settings/vehicles"
    }
  },
  {
    path: '/vle',
    name: 'vehEdit',
    component: () => import('../components/VehEdit'),
    meta:{
          doc: "using-settings/vehicles"
    }
  },
  {
    path: '/bll',
    name: 'bl',
    component: () => import('../components/BLList'),
    meta:{
          doc: "using-settings/body-locations"
    }
  },
  {
    path: '/blc',
    name: 'BLCreate',
    component: () => import('../components/BLAdd'),
    meta:{
          doc: "using-settings/body-locations"
    }
  },
  {
    path: '/ble',
    name: 'BLEdit',
    component: () => import('../components/BLEdit'),
    meta:{
          doc: "using-settings/body-locations"
    }
  },
  {
    path: '/srl',
    name: 'srl',
    component: () => import('../components/SRList'),
    meta:{
          doc: "using-settings/service-requirements"
    }
  },
  {
    path: '/src',
    name: 'SRCreate',
    component: () => import('../components/SRAdd'),
    meta:{
          doc: "using-settings/service-requirements"
    }
  },
  {
    path: '/sre',
    name: 'SREdit',
    component: () => import('../components/SREdit'),
    meta:{
          doc: "using-settings/service-requirements"
    }
  },
  {
    path: '/pl',
    name: 'pers',
    component: () => import('../components/PersList'),
    meta:{
          doc: "using-settings/personnel"
    }
  },
  {
    path: '/plc',
    name: 'persCreate',
    component: () => import('../components/PersAdd'),
    meta:{
          doc: "using-settings/personnel"
    }
  },
  {
    path: '/ple',
    name: 'persEdit',
    component: () => import('../components/PersEdit'),
    meta:{
          doc: "using-settings/personnel"
    }
  },
  {
    path: '/edit/:id',
    name: 'theEdit',
    component: () => import('../components/ServiceEdit'),
    meta:{
          doc: "editing-and-deleting-a-service"
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;