<template>
    <div style="display: table;
  margin-right: auto;
  margin-left: auto; text-align: center">
    <h1>FSS Knowledge Base</h1>
    
     <div style="display: table;
  margin-right: auto;
  margin-left: auto; text-align: center">
    <h2>Setting Up After Payment</h2>
    <iframe width="100%" height="200px"
    src="https://www.youtube.com/embed/tgbNymZ7vqY">
    </iframe>
    </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
               
            }
        },
        created() {
            
        },
        methods: {
            
        }
    }
</script>

<style>
 
</style>