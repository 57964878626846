<template>
  <div class="mainbg" style="position:absolute;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;">
  <div class="welcome" v-if="signedIn == false && this.$route.name !== 'signup' && formState !== 'signIn' && this.$route.name !== 'payment' && this.$route.name !== 'help'">
<!--navigation-->
<section class="smart-scroll galaxy">
        <nav class="navbar navbar-expand-md navbar-dark bg-dark py-1" aria-label="Fourth navbar example" style="padding: 10px; background: linear-gradient(to bottom right, #17B169, #006400); border: 2px solid black; border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarsExample04">
        <ul class="navbar-nav me-auto mb-2 mb-md-0">
          <li class="nav-item">
            <a class="nav-link pr-3 text-light" href="#features">Features</a>
          </li>
          <li class="nav-item">
            <a class="nav-link pr-3 text-light" href="#pricing">Pricing</a>
          </li>
          <li class="nav-item">
            <a class="nav-link pr-3 text-light" href="#faq">FAQ</a>
          </li>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <li class="nav-item" style="">
            <button @click="goToSignUp('signIn')" class='btn text-light' style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border: 2px solid black; background: navy">Sign In</button>
          </li>
          
        </ul>
      </div>
    </nav>
</section>
<!--hero header-->
<section class="py-7 py-md-0 bg-hero galaxy" id="home" style="height: 250px; border-bottom-left-radius: 50px; border-bottom-right-radius: 50px; border-bottom: 5px solid black">
    <!--<div class="container">-->
    <!--    <div class="row vh-md-100">-->
    <!--        <div class="col-md-8 col-sm-10 col-12 mx-auto my-auto text-center">-->
    <div style="height: 70%;


  display: flex;


  align-items: center;


  justify-content: center;">
              <img style="height: 175%;" src="../src/assets/FSSGreen.png">
            </div>
    <!--        </div>-->
    <!--        </div>-->
    <!--</div>-->
    <div style="height: 20%; text-align: center; margin-bottom: 3px">
              <h1 style="color: white">Funeral Service Software</h1>
            </div>
            
</section>
<br>
<br>
<!-- features section -->
<section id="features">
  <div class="row">
            <div class="col-md-6 mx-auto text-center">
                <h1 class="heading-black" style="color: #003399; font-size: 300%; text-shadow: 2px 0px 4px #000000;">Features</h1>
            </div>
        </div>
    <div class="container">
        <div class="row mt-5">
            <div class="col-md-10 mx-auto">
                <div class="row feature-boxes">
                    <div class="col-md-6 box">
                        <div class="icon-box box-primary">
                            <div class="icon-box-inner">
                                <span data-feather="edit-3" width="35" height="35"></span>
                            </div>
                        </div>
                        <br>
                        <div class="card" style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border: 2px solid black; padding: 5%; height: 90%; background: linear-gradient(to bottom right, #17B169, #006400)">
                        <h5 class="" style="color: white; font-size: 125%"><b>Add once. View everywhere.</b></h5>
                        <p style="margin-bottom: 10%" class="text-light">When you add or edit a service in the system from any device, it shows on every other device using your software in real time, including your TV display.</p>
                    </div></div>
                    <div class="col-md-6 box">
                        <div class="icon-box box-primary">
                            <div class="icon-box-inner">
                                <span data-feather="edit-3" width="35" height="35"></span>
                            </div>
                        </div>
                        <br>
                        <div class="card" style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border: 2px solid black; padding: 5%; height: 90%; background: linear-gradient(to bottom right, #17B169, #006400)">
                        <h5 class="" style="color: white; font-size: 125%"><b>Easy-to-access</b></h5>
                        <p style="margin-bottom: 10%" class="text-light">You can use the full power of the software directly from your phone or any device with an internet connection.</p>
                    </div></div>
                    <div class="col-md-6 box">
                        <div class="icon-box box-success">
                            <div class="icon-box-inner">
                                <span data-feather="monitor" width="35" height="35"></span>
                            </div>
                        </div>
                        <br>
                        <div class="card bg-dark" style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border: 2px solid black; padding: 5%; height: 90%; background: linear-gradient(to bottom right, #17B169, #006400)">
                        <h5 class="" style="color: white; font-size: 125%"><b>Customized TV View</b></h5>
                        <p style="margin-bottom: 10%" class="text-light">Instead of using an unorganized whiteboard or something similar to keep track of your services and their information, you can use a smartTV with this software and it will show any details about the service you want in any format you want. You can see whatever information you want at a glance completely accurately and updating in real time.</p>
                    </div></div>
                    <div class="col-md-6 box">
                        <div class="icon-box box-danger">
                            <div class="icon-box-inner">
                                <span data-feather="layout" width="35" height="35"></span>
                            </div>
                        </div>
                        <br>
                        <div class="card bg-dark" style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border: 2px solid black; padding: 5%; height: 90%; background: linear-gradient(to bottom right, #17B169, #006400)">
                        <h5 class="" style="color: white; font-size: 125%"><b>Integrated Casket Inventory System</b></h5>
                        <p style="margin-bottom: 10%" class="text-light">Input your casket inventory once and have it constantly updated. When a service is added to the schedule, the casket being used is removed from the inventory. See the quantity and types of caskets you have in your inventory quickly and easily from anywhere.</p>
                    </div></div>
                    <div class="col-md-6 box">
                        <div class="icon-box box-info">
                            <div class="icon-box-inner">
                                <span data-feather="globe" width="35" height="35"></span>
                            </div>
                        </div>
                        <br>
                        <div class="card bg-dark" style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border: 2px solid black; padding: 5%; height: 90%; background: linear-gradient(to bottom right, #17B169, #006400)">
                        <h5 class="" style="color: white; font-size: 125%"><b>Multiple Accounts</b></h5>
                        <p style="margin-bottom: 10%" class="text-light">Each customer can create as many accounts for different people as needed with different permissions for each one. You can give access to your local greenhouse(s) or vault company so they can view some things, but not edit your services. Inviting someone is as simple as clicking a button and entering the email of whoever yoou want to invite. This generates a shareable link that opens a page for them to sign up.</p>
                    </div></div>
                    <div class="col-md-6 box">
                        <div class="icon-box box-info">
                            <div class="icon-box-inner">
                                <span data-feather="globe" width="35" height="35"></span>
                            </div>
                        </div>
                        <br>
                        <div class="card bg-dark" style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border: 2px solid black; padding: 5%; height: 90%; background: linear-gradient(to bottom right, #17B169, #006400)">
                        <h5 class="" style="color: white; font-size: 125%"><b>Easy-to-access Information for Service Locations and Cemeteries.</b></h5>
                        <p style="margin-bottom: 10%" class="text-light">Call the service location or cemetery associated with a service directly from that service. If you've added an address for it, you can also click a link to get directions via google maps.</p>
                    </div></div>
                    <div class="col-md-6 box">
                        <div class="icon-box box-info">
                            <div class="icon-box-inner">
                                <span data-feather="globe" width="35" height="35"></span>
                            </div>
                        </div>
                        <br>
                        <div class="card bg-dark" style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border: 2px solid black; padding: 5%; height: 90%; background: linear-gradient(to bottom right, #17B169, #006400)">
                        <h5 class="" style="color: white; font-size: 125%"><b>Recently Deleted Section to Avoid Misclicks.</b></h5>
                        <p style="margin-bottom: 10%" class="text-light">Mistakes happen. If someone goes in and deletes pretty much anything in the software that you don't want deleted, you can easily go restore them with all of their information. Almost nothing in the software is ever fully deleted.</p>
                    </div></div>
                    <div class="col-md-6 box">
                        <div class="icon-box box-info">
                            <div class="icon-box-inner">
                                <span data-feather="globe" width="35" height="35"></span>
                            </div>
                        </div>
                        <br>
                        <div class="card bg-dark" style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border: 2px solid black; padding: 5%; height: 90%; background: linear-gradient(to bottom right, #17B169, #006400)">
                        <h5 class="" style="color: white; font-size: 125%"><b>Constantly Updating Features</b></h5>
                        <p style="margin-bottom: 10%" class="text-light">The features of this software are constantly improving any time a customer has an idea. The features shown here are just a small percentage of the features the software has currently and an even smaller percentage of the features the software will have in the future!</p>
                    </div></div>
                </div>
            </div>
    </div>
    </div>
</section>
<br>
<hr style="height:5px;
  border-width:0;
  background-color:#003399">
<br>
<!--pricing section-->
<section class="py-7 section-angle top-right bottom-right" id="pricing">
    <div class="container">
        <div class="row">
            <div class="col-md-6 mx-auto text-center">
                <h1 class="heading-black" style="color: #003399; font-size: 300%; text-shadow: 2px 0px 4px #000000;">Pricing</h1>
            </div>
        </div>
        <!--pricing tables-->
        <div class="row pt-5 pricing-table">
            <div class="col-12 mx-auto">
                <div class="pricing-table">
                    <div class="card bg-dark" style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border: 2px solid black; padding: 0%; height: 100%; background: linear-gradient(to bottom right, #17B169, #006400); text-align: center">
                            <h3 class="text-light pt-3">Unlimited Use</h3>
                            <h2 class="mb-0 pt-4" style="color: blue">$1000</h2>
                            <div class="text-light font-weight-medium mt-2">One-time Set Up Cost</div>
                            <h2 class="mb-0 pt-4" style="color: blue">$25</h2>
                            <div class="text-light font-weight-medium mt-2">per month</div>
                            <br>
                            <button id="myButton" onclick="location.href = 'https://buy.stripe.com/fZeaHq60e1pI65i9AC'" class="btn btn-primary" style="background-color: #003399; width: 100%; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px">Pay by Card</button>
                    </div>
                </div>
            </div>
        </div>
            
    </div>
</section>
<br>
<hr style="height:5px;
  border-width:0;
  background-color:#003399;">
<br>
<!--faq section-->
<section class="py-7" id="faq">
    <div class="container">
        <div class="row">
            <div class="col-md-6 mx-auto text-center">
                <h1 class="" style="color: #003399; font-size: 300%; text-shadow: 2px 0px 4px #000000;">Frequently Asked Questions</h1>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-10 mx-auto">
                <div class="row">
                  <div class="col-md-6 mb-5">
                        <div class="card bg-dark" style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border: 2px solid black; padding: 5%; height: 100%; background: linear-gradient(to bottom right, #17B169, #006400)">
                        <h5 class="" style="color: white; font-size: 125%"><b>What information can I add to each service?</b></h5>
                        <p style="margin-bottom: 10%" class="text-light">You can add pretty much any information you can think of to a service. If something you want isn't there, just let us know and we can probably get it added! Some of the information that can currently be added to each service is name, date, current body location, service location, cemetery location (along with a 'contacted' checkbox), the time and date that family is coming in, casket, visitation, personnel, director, vehicles, service requirements (with 'done' checks for each one), along with more information that is always expanding.</p>
                    </div></div>
                    <div class="col-md-6 mb-5">
                        <div class="card bg-dark" style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border: 2px solid black; padding: 5%; height: 100%; background: linear-gradient(to bottom right, #17B169, #006400)">
                        <h5 class="" style="color: white; font-size: 125%"><b>Will I have to pay multiple times if I have multiple funeral home locations?</b></h5>
                        <p style="margin-bottom: 10%" class="text-light">Depending on how independent your different locations are, you may be able to roll them all into only one payment. The main determining factor is if you need different sets of services for each funeral home. For example, if Location 1 has John Doe as a service, can Location 2 see it and edit it too? If the answer is yes, then you can use the same software payment to cover both locations. Otherwise, you will need to get the software separately for each location.</p>
                    </div></div>
                    <div class="col-md-6 mb-5">
                        <div class="card bg-dark" style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border: 2px solid black; padding: 5%; height: 100%; background: linear-gradient(to bottom right, #17B169, #006400)">
                        <h5 class="" style="color: white; font-size: 125%"><b>What are the payment methods you accept?</b></h5>
                        <p style="margin-bottom: 10%" class="text-light">A credit/debit card through the website would be best, but a check would also work along with possibly more payment methods. If you don't want to pay by card, send me an email and we can figure out what would work best for you.</p>
                    </div></div>
                    <div class="col-md-6 mb-5">
                        <div class="card bg-dark" style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border: 2px solid black; padding: 5%; height: 100%; background: linear-gradient(to bottom right, #17B169, #006400)">
                        <h5 class="" style="color: white; font-size: 125%"><b>How often do you release updates?</b></h5>
                        <p style="margin-bottom: 10%" class="text-light">Updates are released almost any time any customer requests something to be added. This could be every week sometimes or it could be months between updates depending on what customers want added.</p>
                    </div></div>
                    <div class="col-md-6 mb-5">
                        <div class="card bg-dark" style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border: 2px solid black; padding: 5%; height: 100%; background: linear-gradient(to bottom right, #17B169, #006400)">
                        <h5 class="" style="color: white; font-size: 125%"><b>How can I contact you?</b></h5>
                        <p style="margin-bottom: 10%" class="text-light">The best place to reach us is by email. Hit the button below to contact us and we'll get back to you as soon as possible!</p>
                    </div></div>
                    <div class="col-md-6 mb-5">
                        <div class="card bg-dark" style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border: 2px solid black; padding: 5%; height: 100%; background: linear-gradient(to bottom right, #17B169, #006400)">
                        <h5 class="" style="color: white; font-size: 125%"><b>Can I get a demo?</b></h5>
                        <p style="margin-bottom: 10%" class="text-light">Of course! Email us and we can set up a demo either virtually or in person.</p>
                    </div></div>
                    <div class="col-md-6 mb-5">
                        <div class="card bg-dark" style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border: 2px solid black; padding: 5%; height: 100%; background: linear-gradient(to bottom right, #17B169, #006400)">
                        <h5 class="" style="color: white; font-size: 125%"><b>What kind of TV do I need for the TV display part of the software?</b></h5>
                        <p style="margin-bottom: 10%" class="text-light">Any TV that has access to the internet will work. If you don't know what to get, you can email us and we can help figure out what you need.</p>
                    </div></div>
                    <div class="col-md-6 mb-5">
                        <div class="card bg-dark" style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border: 2px solid black; padding: 5%; height: 100%; background: linear-gradient(to bottom right, #17B169, #006400)">
                        <h5 class="" style="color: white; font-size: 125%"><b>What can I customize in the software?</b></h5>
                        <p style="margin-bottom: 10%" class="text-light">The TV view is fully customizable. Color, layout and the information displayed can all be changed. Most other aspects of the software need to be the same across different customers, but if you put in a request with an idea to change something or add something that would be beneficial to all customers, it can be added or changed.</p>
                    </div></div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-6 mx-auto text-center" style="text-align: center;">
                <h4 class="mb-4" style="color: #003399">Have questions or want a demo?</h4>
                <a :href="'mailto:' + 'tyler@tylercantrell.com'" class="btn btn-primary" style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; width: 40%; height: 30%; border: 2px solid black; background: linear-gradient(to bottom right, #17B169, #006400)"><p style="font-size: 100%">Send an Email</p></a>
            </div>
        </div>
        <br>
        <br>
    </div>
</section>
    
    
    
    
  </div>
        <div v-if="formState === 'signIn'" class="welcome" style="flex-direction: column; justify-content: center; display: flex; padding: 10px; margin: 0;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;">
    <div style="flex-direction: column; justify-content: center; display: flex; padding: 10px; margin: 0;
  position: absolute;
  top: 25%;
  bottom: 25%;
  left: 25%;
  right: 25%;">
      <h2 class="shadowtext" style="color: #17B169"><b>Sign In</b></h2>
      <div class="form-group">
          <label class="shadowtext" style="color: white">Username</label>
          <input style="border: 2px solid #484848; width: 100%" placeholder="Username" v-model='form2.username' class='form-control' />
      </div>
      <div class="form-group">
          <label class="shadowtext" style="color: white">Password</label>
          <input style="border: 2px solid #484848; width: 100%" type="password" placeholder="Password" v-model='form2.password' class='form-control' />
      </div>
      <br>
      <button style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; width: 100%; height: 50px; border: 2px solid black; background: linear-gradient(to bottom right, #17B169, #006400); text-align: center" v-on:click='signIn' class='btn btn-primary btn-block'>Sign In</button><br>
      <br>
      <br>
      <button @click="goToSignUp('')" class='btn btn-primary btn-block' style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; width: 100%; height: 50px; border: 2px solid black; background: linear-gradient(to bottom right, blue, #003399)">Back to Homepage</button>
    </div>
  </div>
  <div v-if="signedIn == true && userVerified == 'true'" class="mainbg">
    <nav style="padding: 15px; border: 2px solid black; border-bottom-left-radius: 15px; border-bottom-right-radius: 15px; background: linear-gradient(to bottom right, #006400, #17B169)" class="navbar navbar-expand-md navbar-dark bg-secondary py-1" aria-label="Fourth navbar example" v-if="this.$route.name !== 'tv' && this.$route.name !== 'condensed' && this.$route.name !== 'welcome'">
      <div class="container-fluid" style="margin: 0px; padding: 0px">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarsExample04">
        <ul class="navbar-nav me-auto mb-2 mb-md-0" style="padding-bottom: 10px; padding-top: 5px; font-size: 105%; display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;">
          <li class="nav-item" style="border-radius: 10px; background-color: #004aad; font-size: 95%; text-align: center; width: 95%; border: 2px solid black">
            <router-link class="nav-link pr-3 text-light" to="/add">Add Service</router-link>
          </li>&nbsp;&nbsp;&nbsp;
          <li class="nav-item" style="border-radius: 10px; background-color: #004aad; font-size: 95%; text-align: center; width: 95%; border: 2px solid black">
            <router-link class="nav-link text-light" to="/">View Services</router-link>
          </li>&nbsp;&nbsp;&nbsp;
          <li class="nav-item" style="border-radius: 10px; background-color: #004aad; font-size: 95%; text-align: center; width: 95%; border: 2px solid black">
            <router-link class="nav-link text-light" to="/tv">TV View</router-link>
          </li>&nbsp;&nbsp;&nbsp;
          <li class="nav-item" style="border-radius: 10px; background-color: #004aad; font-size: 95%; text-align: center; width: 95%; border: 2px solid black">
            <router-link class="nav-link text-light" to="/condensed">Condensed View</router-link>
          </li>&nbsp;&nbsp;&nbsp;
         <li class="nav-item dropdown text-light" style="border-radius: 10px; background-color: #004aad; font-size: 95%; text-align: center; width: 95%; border: 2px solid black">
              <a style="" class="nav-link dropdown-toggle text-light" href="#" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false"><b>Settings</b></a>
              <ul class="dropdown-menu bg-secondary" aria-labelledby="dropdown04" style="background: linear-gradient(to bottom right, #17B169, #006400); border-radius: 10px; width: 100%; text-align: center; border: 2px solid black">
                <li><router-link to="/sl" class="dropdown-item text-light">Service Locations</router-link></li>
                <li><router-link to="/cl" class="dropdown-item text-light">Cemeteries</router-link></li>
                <li><router-link to="/vl" class="dropdown-item text-light">Vehicles</router-link></li>
                <li><router-link to="/pl" class="dropdown-item text-light">Personnel</router-link></li>
                <li><router-link to="/bll" class="dropdown-item text-light">Body Locations</router-link></li>
                <li><router-link to="/srl" class="dropdown-item text-light">Service Requirements</router-link></li>
                <li><router-link to="/containerlist" class="dropdown-item text-light">Containers</router-link></li>
                 <li><router-link to="/colorlist" class="dropdown-item text-light">Casket Colors</router-link></li>
                <li><router-link to="/casketlist" class="dropdown-item text-light">Casket Inventory</router-link></li>
                <li><router-link to="/deleted" class="dropdown-item text-light">Recently Deleted</router-link></li>
                <li v-if="$isFHAdmin == true" class="nav-item">
                  <router-link class="dropdown-item text-light" to="/admin">New User Approval</router-link>
                </li>
                <!--<li v-if="$isFHAdmin == true" class="nav-item">-->
                <!--  <router-link class="dropdown-item text-light" to="/payment">Payment</router-link>-->
                <!--</li>-->
                <br>
                <li style="text-align: center;"><button style="width: 80%" v-on:click='signOut' class='btn btn-danger btn-block'>Sign Out</button></li>
              </ul>
            </li>
            &nbsp;&nbsp;&nbsp;<button style="width: 95%; border: 2px solid black; border-radius: 10px" v-on:click="goToDocs" class="btn btn-info"><b>Help</b></button>
        </ul>
      </div>
      </div>
      
    </nav>
    <div style="padding: 20px" v-if="this.$route.name != 'tv' && this.$route.name != 'condensed'">
      <router-view />
    </div>
    <div v-if="this.$route.name == 'tv' || this.$route.name == 'condensed'">
      <router-view />
    </div>
</div>
<div v-if="this.$route.name == 'payment'">
  <Payment/>
</div>
<div v-if="this.$route.name == 'help'">
  <Help/>
</div>
<div v-if="userVerified == 'false' && signedIn == true" style="display: flex; align-items: center; justify-content: center">
  <h1 class="text-center">Waiting for Approval... <br><br><button style="width: 80%; margin: auto" v-on:click='signOut' class='btn btn-danger btn-block'>Sign Out</button></h1>
</div>

<div v-if="userVerified == 'view' && signedIn == true" style="text-align: center;">
  <Condensed/>
  <button style="width: 80%; margin: auto" v-on:click='signOut' class='btn btn-danger btn-block'>Sign Out</button>
</div>
<div v-if="this.$route.name == 'signup' && signedIn == false">
  <router-view />
</div>
</div>
</template>

<script>
import Help from './components/Help'
import Payment from './components/Payment'
import Condensed from './components/Condensed'
import Auth from '@aws-amplify/auth';
import { Hub } from 'aws-amplify';
import { db } from './firebaseDb';
export default {
  name: "App",
  components: {
    Condensed,
    Payment,
    Help,
  },
  data() {
            return {
                isAdmin: false,
                useremail: "",
                signedIn: false,
                formState: '',
                form: {
                  username: '',
                  password: '',
                  email: '',
                },
                form2: {
                  username: '',
                  password: '',
                },
                tenants: [],
                userVerified: 'false',
                hasSignedOut: false,
                username: "",
                maincolor: '#003399',
            }
        },
  created() {
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.signedIn = true
      })
      .catch(() => this.signedIn = false)
    const listener = (data) => {
    switch (data.payload.event) {
        case 'signIn':
                Auth.currentAuthenticatedUser()
      .then(user => {
        this.signedIn = true
        db.collection('pendingusers').onSnapshot((snapshotChange) => {
                snapshotChange.forEach((doc) => {
                    if(String(doc.data().username).toLowerCase() == String(user.username).toLowerCase()) {
                        if(doc.data().approved == 'true' || doc.data().approved == 'admin') {
                          this.userVerified = 'true';
                          Auth.updateUserAttributes(user, {
                            'custom:approved': 'true'
                          });
                        }
                        if(doc.data().approved == 'false') {
                          this.userVerified = 'false';
                          Auth.updateUserAttributes(user, {
                            'custom:approved': 'false'
                          });
                        }
                        if(doc.data().approved == 'view') {
                          this.userVerified = 'view';
                          Auth.updateUserAttributes(user, {
                            'custom:approved': 'view'
                          });
                        }
                        if(doc.data().approved == 'admin') {
                          this.isAdmin = true;
                          this.userVerified = 'true'
                        }
                    }
                })
                })
        console.log(Auth.currentAuthenticatedUser())
        window.location.reload();
      })
      .catch(() => this.signedIn = false)
                  
            }
    }
  Hub.listen('auth', listener);

  Auth.currentAuthenticatedUser({
            }).then(user => { 
              this.username = user.username;
                  db.collection('pendingusers').onSnapshot((snapshotChange) => {
                snapshotChange.forEach((doc) => {
                    if(String(doc.data().username).toLowerCase() == String(user.username).toLowerCase()) {
                        if(doc.data().approved == 'true' || doc.data().approved == 'admin') {
                          this.userVerified = 'true';
                          Auth.updateUserAttributes(user, {
                            'custom:approved': 'true'
                          });
                        }
                        if(doc.data().approved == 'false') {
                          this.userVerified = 'false';
                          Auth.updateUserAttributes(user, {
                            'custom:approved': 'false'
                          });
                        }
                        if(doc.data().approved == 'view') {
                          this.userVerified = 'view';
                          Auth.updateUserAttributes(user, {
                            'custom:approved': 'view'
                          });
                        }
                        if(doc.data().approved == 'admin') {
                          this.isAdmin = true;
                          this.userVerified = 'true'
                        }
                    }
                })
                });
            })
            console.log("TENANT: "+this.$tenant)
            
  },
  methods: {
    goToDocs() {
      var link = ""
      link = "https://docs.funeralservicesoftware.com/tutorials/"+this.$route.meta.doc
      window.location.href = link
    },
    featuredisabled() {
      alert("Sorry, this feature isn't fully available yet. For now, you can email me and I can send you a link to pay.")
    },
    async signIn() {
      try {
        const { username, password } = this.form2
        await Auth.signIn(username, password)
        this.form = {
                  username: '',
                  password: '',
                }
                this.formState = ''
    } catch (error) {
      if(String(error) !== "Error: Pending sign-in attempt already in progress") {
        if(String(error).includes("Custom auth lambda trigger")) {
          alert("Password cannot be empty.")
        }
        else {
          alert(String(error).split(':')[1]);
        }
      }
      else {
        alert("Please Wait.")
      }
    }
    },
    async goToSignUp(state) {
        this.formState = state
    },
    async signUp() {
      const { username, password, email, fh } = this.form
      if(fh !== null && fh !== "") {
      try {
          await Auth.signUp({
            username, password, attributes: { email, 'custom:fh': fh, 'custom:approved': 'false' }
          })
          alert("Submitted For Approval!");
          this.form = {
                  username: '',
                  password: '',
                  email: '',
                }
      } catch (error) {
      if(String(error) !== "Error: Pending sign-in attempt already in progress") {
        if(String(error).includes("validation error") || String(error).includes("did not conform with policy")) {
          alert("Password security is too low. A password requires at least one captial letter and at least one number")
        }
        if(String(error).includes("auto verified was set to true")) {
          alert("Sign up requires an email.")
        }
        if(!String(error).includes("validation error") && !String(error).includes("did not conform with policy") && !String(error).includes("auto verified was set to true")) {
          alert(String(error).split(':')[1]);
        }
      }
      }
      }
      else {
        alert("Please Select a Funeral Home.")
      }
      const user = ({
        username, fh, approved:false
      })
      db.collection('pendingusers').add(user).then(() => {
                }).catch((error) => {
                    console.log(error);
                });
        await Auth.signIn(username, password)
    },
    async signOut() {
      await Auth.signOut().then(() => {
        console.log(Auth.currentAuthenticatedUser())
        this.signedIn = false;
        this.hasSignedOut = true;
      })
  },
  },
  
};
</script>
<style>
.welcome {
  background-color: lightgrey;
}
.mainbg {
  background-color: lightgrey;
}
.signIn {
  background: linear-gradient(to bottom right, #17B169, #006400);
}
.logo-image{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 0px;
    float: right;
    margin-right: 25px;
    margin-bottom: 5px;
}
h4 {
  width: 100%;
  padding: 20px;
}
    .shadowtext {
        text-shadow: 0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000;
    }
    .shadowtextwhite {
        text-shadow: 0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF;
    }
    .galaxy {
      background: linear-gradient(to bottom right,midnightblue, #1034A6)
    }
    html,body
{
  position: relative;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}


.dropdown-menu a:hover{
  background: #004aad !important;
}
</style>