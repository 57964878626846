import { createApp } from 'vue'
import App from './App.vue'
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import router from './router'
import { loadFonts } from './plugins/webfontloader'
import Auth from '@aws-amplify/auth';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

import './registerServiceWorker'
import { db } from './firebaseDb';

Amplify.configure(aws_exports);
applyPolyfills().then(() => {
  defineCustomElements(window);
});
loadFonts()
//var thetenant;

const app = createApp(App);
app.use(router);
Auth.currentAuthenticatedUser({
            }).then(user => { 
                db.collection('pendingusers').onSnapshot((snapshotChange) => {
                snapshotChange.forEach((doc) => {
                    if(String(doc.data().username).toLowerCase() == String(user.username).toLowerCase()) {
                      //thetenant = doc.data().fh
                      app.config.globalProperties.$tenant = doc.data().fh
                        if(doc.data().approved == 'admin') {
                          app.config.globalProperties.$isFHAdmin = true;
                          console.log(app.config.globalProperties.$isFHAdmin)
                        }
                    }
                })
                })
                // var groups2 = user.signInUserSession.accessToken.payload["cognito:groups"]
                // for( var i = 0; i < groups2.length; i++){ 
                // if ( groups2[i] === "fhadmin") { 
                //     groups2.splice(i, 1); 
                // }
                // //thetenant = groups2[0]
                // }
              
            }).then(() => {
              //app.config.globalProperties.$tenant = thetenant;
              console.log("GLOBAL; "+app.config.globalProperties.$tenant)
              })
app.mount('#app');
  